import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IconButton, makeStyles, Snackbar, LinearProgress } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { usePage1Request, useLytics } from 'src/hooks';
import { Header } from 'src/blocks/Header/Header';
import { defaultStyles } from 'src/util';

import { Content } from './Content/Content';
import { GetInit } from './type';
import {
  RootState,
  NETWORK_ERROR__DISMIS,
  PAGE__SET,
  OauthLoginAction,
  APP__OAUTH_LOGIN_ACTION,
  APP__SET_UID_ACTION,
  SetUidAction,
} from './reducers';
import { getTheme } from './theme';
import { OfflineIndication } from './blocks/OfflineIndication';
import { NotAvailable } from './blocks/NotAvailable/NotAvailable';

const useStyles = makeStyles({
  '@global': {
    body: ({ backgroundColor, fontColor }: GetInit['styles'] | Record<string, string>) => ({
      height: '100vh',
      background: backgroundColor || defaultStyles.backgroundColor,
      color: fontColor,
      padding: 0,
      margin: 0,
      '& #outdated': {
        padding: 24,
        '& .last': {
          top: 4,
          right: 10,
        },
      },
    }),
  },
  app: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  linearProgressRoot: (data) => {
    const { headerColor } = (data || {}) as GetInit['styles'];
    return {
      backgroundColor: headerColor || 'transparent',
      position: 'absolute',
      width: '100%',
      top: '0',
    };
  },
  linearProgressBar: (data) => {
    const { buttonColor = defaultStyles.buttonColor } = (data || {}) as GetInit['styles'];
    return {
      backgroundColor: buttonColor,
    };
  },
});

const getFontUrl = (fontName: string, weights: number[]) => {
  const weightString = weights.join(';');
  return `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
    fontName,
  )}:wght@${weightString}&display=swap`;
};

const App: FC = () => {
  usePage1Request();
  const [currentTheme, setCurrrentTheme] = useState(getTheme());
  const [fonts, setFonts] = useState([{ font: 'Roboto', weights: [400, 500, 600] }]);
  const { app: appState, form, networkError, page } = useSelector((root: RootState) => root);
  const dispatch = useDispatch();
  useLytics((uid) => {
    const action: SetUidAction = {
      type: APP__SET_UID_ACTION,
      payload: uid,
    };
    dispatch(action);
  });
  const initResponse = appState.page1.response;
  const styles = useStyles(initResponse?.styles || {});
  const errorState = !!appState.page1.error;
  const loading = appState.requestsCount > 0;

  const setPage = (payload: number): void => {
    dispatch({
      type: PAGE__SET,
      payload,
    });
  };

  useEffect(() => {
    if (!initResponse?.styles.fontFamily) return;
    setFonts([{ font: initResponse.styles.fontFamily, weights: [400, 500, 600] }]);
    setCurrrentTheme(getTheme(initResponse.styles));
  }, [initResponse]);

  useEffect(() => {
    const listener = (event: WindowEventMap['message']): void => {
      if (event.data.type !== 'oauth_login') return;
      const action: OauthLoginAction = {
        type: APP__OAUTH_LOGIN_ACTION,
        payload: event.data.payload,
      };
      dispatch(action);
    };
    window.addEventListener('message', listener);
    return (): void => window.removeEventListener('message', listener);
  });

  const { title, message } = appState.securityError || networkError || {};

  return (
    <HelmetProvider>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <Helmet>
          <title>{initResponse?.form.title}</title>
          <meta name="description" content={initResponse?.form.subtitle} />
          {fonts.map(({ font, weights }) => (
            <link key={font} rel="stylesheet" href={getFontUrl(font, weights)} />
          ))}
        </Helmet>
        {!!initResponse && (
          <div className={styles.app}>
            <Header
              stylesData={initResponse?.styles}
              titleData={initResponse?.form}
              errorState={errorState}
              page={page}
            />
            <Content
              appState={appState}
              formState={form}
              loading={loading || errorState}
              setPage={setPage}
              page={page}
            />
          </div>
        )}
        {networkError && !initResponse && <NotAvailable />}
        {loading ? (
          <LinearProgress
            classes={{
              root: styles.linearProgressRoot,
              bar: styles.linearProgressBar,
            }}
          />
        ) : null}
        <Snackbar
          open={!!networkError || !!appState.securityError}
          message={
            <span>
              {title}
              <br />
              {message}
            </span>
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          action={
            !appState.securityError && (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(): void => {
                  dispatch({ type: NETWORK_ERROR__DISMIS });
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }
        />
        <OfflineIndication />
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
