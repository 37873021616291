import { FC, useState, useEffect } from 'react';
import { Grid, Box, Typography, InputLabel, Hidden, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, PersonalList } from 'src/blocks';
import {
  RootState,
  Page2PhoneSubmitPayload,
  APP__POST_PHONE_ACTION,
  FORM__SUBMIT_PAGE2_PHONE,
  FormSubmitPage2PhoneAction,
  FormState,
} from 'src/reducers';
import { useApiClient } from 'src/hooks';
import { defaultPreviewResponse } from 'src/util';

import { TargetingItem } from 'src/type';
import { makeSchema } from './Call.schema';
import { useStyles } from './Call.style';
import { Props } from '../Contents.type';
import { NextStepButton } from '../NextStepButton';

const basePathname = process.env.REACT_APP_API_BASE_PATHNAME;

type FormValues = {
  from: string;
  script: string;
  targets: string;
};

export const Call: FC<
  Props & { isAdvocateDevice: boolean; formState: FormState['page2PhoneForm'] }
> = ({
  isAdvocateDevice,
  setPage,
  postStore,
  urlQuery,
  haveDoneActions,
  sendDone,
  targets,
  formState,
  onSubmitEvent,
  previewMode,
}) => {
  const schema = makeSchema(previewMode);
  const apiClient = useApiClient();
  const { page1Form, initStyles, staticPageMode } = useSelector(({ app, form }: RootState) => ({
    page1Form: form.page1Form,
    initStyles: app.page1.response?.styles,
    staticPageMode: app.staticStepMode,
  }));
  const styles = useStyles(initStyles);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const [submitedPayload, setSubmitedPayload] = useState<Page2PhoneSubmitPayload | null>(null);
  const [selectedTarget, setSelectedTarget] = useState<TargetingItem | null>(null);

  useEffect(() => {
    if (!submitedPayload) return undefined;
    let actual = true;
    apiClient
      .post({
        type: APP__POST_PHONE_ACTION,
        uri: `${basePathname}/${urlQuery?.uc}/${urlQuery?.uf}/action/phonecall`,
        payload: submitedPayload,
        query: { token: urlQuery.token },
        previewResponse: previewMode ? defaultPreviewResponse : undefined,
      })
      .then(([, error]) => {
        if (!actual) return;
        setSubmitedPayload(null);
        setSelectedTarget(null);
        if (!error) return;
        (error.errors || []).forEach(({ path, message }) => {
          const key = path.replace(/^phoneCall\./, '') as keyof FormValues;
          setError(key, { message });
        });
      });
    return (): void => {
      actual = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitedPayload, urlQuery]);

  const onSubmit = (formValues: FormValues): void => {
    if (staticPageMode) {
      onSubmitEvent();
      return;
    }
    const currentTarget = selectedTarget || (previewMode && targets.find(({ sended }) => !sended));
    if (!currentTarget) {
      return;
    }
    const { phoneNumber, uuid } = currentTarget;
    const submitPayload: Page2PhoneSubmitPayload = {
      signature: {
        uuid: postStore?.signature?.uuid || '',
      },
      phoneCall: {
        from: formValues.from || page1Form?.phone || '',
        to: phoneNumber || formValues.from,
        script: formValues.script,
      },
      targeting: [{ uuid }],
      // eslint-disable-next-line no-underscore-dangle
      _csrf: postStore?._csrf || '',
    };
    setSubmitedPayload(submitPayload);
    const formAction: FormSubmitPage2PhoneAction = {
      type: FORM__SUBMIT_PAGE2_PHONE,
      payload: submitPayload,
    };
    dispatch(formAction);
    if (selectedTarget?.phoneNumber && isAdvocateDevice) {
      const telNumber = selectedTarget.phoneNumber.replace(/\D/g, '');
      const link = `tel:+${telNumber}`;
      window.open(link, '_blank');
    }
  };

  const phoneAction = postStore?.actions?.phoneCall;
  if (!phoneAction) return null;
  const { script } = phoneAction;

  const callScript = formState?.phoneCall.script || script;
  const targetsWithSelected = targets.map((target) => ({
    ...target,
    selected: target.uuid === selectedTarget?.uuid,
  }));
  const submitDisabled = isAdvocateDevice
    ? false
    : !!submitedPayload ||
      !targets.find(({ sended }) => !sended) ||
      (isAdvocateDevice && !selectedTarget?.phoneNumber);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="phonecall-form">
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} className={styles.list}>
          <PersonalList
            isAdvocateDevice={isAdvocateDevice}
            errorMessage={errors.targets?.message}
            targeting={targetsWithSelected}
            withPhones
            onSelect={setSelectedTarget}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={styles.callBox}>
            {isAdvocateDevice ? (
              <Typography className={styles.title}>
                Click a target to call, then click the button to start a call that will connect you
                with the target. When you are finished, select the next target.
              </Typography>
            ) : (
              <Grid container>
                <Typography className={styles.title}>
                  Click a target to call, enter your phone number and click the button to receive a
                  call that will connect you with the target. When you are finished, select the next
                  target.
                </Typography>
                <TextField
                  id="from"
                  className={styles.necessaryMargin}
                  fullWidth
                  label="Your phone number"
                  defaultValue={formState?.phoneCall.from || page1Form?.phone}
                  variant="outlined"
                  {...register('from')}
                  error={!!errors.from}
                  helperText={errors.from?.message}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <Hidden mdUp>
        <Grid container justify="center">
          <Box className={styles.buttonsBox}>
            {!sendDone && (
              <Button type="submit" disabled={submitDisabled} id="submit-phonecall">
                Start a call
              </Button>
            )}
            <NextStepButton
              active={haveDoneActions}
              handleClick={(): void => setPage(2)}
              id="phonecall-nextstep"
            />
          </Box>
        </Grid>
      </Hidden>
      <Grid container justify="center" className={styles.callScript}>
        <Grid item xs={12}>
          <Box mt={3} mb={2}>
            <InputLabel htmlFor="script" classes={{ root: styles.labelRoot }}>
              Call script
            </InputLabel>
            <TextField
              id="script"
              multiline
              rows={6}
              rowsMax={8}
              className={styles.necessaryMargin}
              fullWidth
              defaultValue={callScript}
              variant="outlined"
              {...register('script')}
              error={!!errors.script}
              helperText={errors.script?.message}
            />
          </Box>
        </Grid>
      </Grid>
      <Hidden only={['sm', 'xs']}>
        <Grid container justify="center" className={styles.buttonsBox}>
          <Button type="submit" disabled={submitDisabled} id="submit-phonecall">
            Start a call
          </Button>
          <NextStepButton
            active={haveDoneActions}
            handleClick={(): void => setPage(2)}
            id="phonecall-nextstep"
          />
        </Grid>
      </Hidden>
      <input type="hidden" {...register('targets', { value: selectedTarget ? 'selected' : '' })} />
    </form>
  );
};
