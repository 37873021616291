import { PostTwitterAction } from 'src/type';

import {
  APP__OAUTH_LOGIN_ACTION,
  APP__POST_TWEET_ACTION,
  OauthLoginAction,
  ResponseAction,
} from '../app/app.type';
import {
  FormState,
  FORM__SUBMIT_PAGE1,
  FormSubmitPage1Action,
  FormSubmitPage2EmailAction,
  FormSubmitPage2PhoneAction,
  FormSubmitPage2TweetAction,
  FORM__SUBMIT_PAGE2_EMAIL,
  FORM__SUBMIT_PAGE2_PHONE,
  FORM__SUBMIT_PAGE2_TWEET,
} from './form.type';

const initState: FormState = {
  page1Form: null,
  page2EmailForm: null,
  page2PhoneForm: null,
  page2TweetForm: null,
};

export const form = (
  state: FormState = initState,
  action:
    | FormSubmitPage1Action
    | FormSubmitPage2EmailAction
    | FormSubmitPage2PhoneAction
    | FormSubmitPage2TweetAction
    | ResponseAction<PostTwitterAction>
    | OauthLoginAction,
): FormState => {
  switch (action.type) {
    case FORM__SUBMIT_PAGE1:
      return { ...state, page1Form: action.payload };
    case FORM__SUBMIT_PAGE2_EMAIL:
      return { ...state, page2EmailForm: action.payload };
    case FORM__SUBMIT_PAGE2_PHONE:
      return { ...state, page2PhoneForm: action.payload };
    case FORM__SUBMIT_PAGE2_TWEET:
      return { ...state, page2TweetForm: action.payload };
    case APP__POST_TWEET_ACTION:
      return { ...state, page2TweetForm: null };
    case APP__OAUTH_LOGIN_ACTION:
      return action.payload?.twitterLoginStatus === 'ok'
        ? state
        : { ...state, page2TweetForm: null };
    default:
      return state;
  }
};
