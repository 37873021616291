import { FC, useState, useEffect, ChangeEvent } from 'react';
import {
  Grid,
  Typography,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import omit from 'lodash/omit';

import {
  RootState,
  Page2EmailSubmitPayload,
  APP__POST_EMAIL_ACTION,
  FORM__SUBMIT_PAGE2_EMAIL,
  FormSubmitPage2EmailAction,
  FormState,
} from 'src/reducers';
import { Button, DisabledState, PersonalList } from 'src/blocks';
import { useApiClient } from 'src/hooks';
import { defaultPreviewResponse } from 'src/util';

import { makeSchema } from './Email.schema';
import { useStyles } from './Email.style';
import { Props } from '../Contents.type';
import { NextStepButton } from '../NextStepButton';
import { EmailVerifyDialog } from './EmailVerifyDialog';
import { useEmailVerifyDialog } from './useEmailVerifyDialog';

const basePathname = process.env.REACT_APP_API_BASE_PATHNAME;

type FormValues = {
  body: string;
  subject: string;
  targets: string;
  byFaxAlso?: boolean;
};

export const Email: FC<Props & { formState: FormState['page2EmailForm'] }> = ({
  setPage,
  postStore,
  urlQuery,
  haveDoneActions,
  targets: rawTargets,
  sendDone,
  formState,
  onSubmitEvent,
  previewMode,
}) => {
  const apiClient = useApiClient();
  const schema = makeSchema(previewMode);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const { page1Form, pageStyles, features, staticPageMode } = useSelector(
    ({ form, app }: RootState) => ({
      page1Form: form.page1Form,
      pageStyles: app.page1.response?.styles,
      features: app.page1.response?.features,
      staticPageMode: app.staticStepMode,
    }),
  );
  const styles = useStyles(pageStyles);
  const dispatch = useDispatch();
  const [submitedPayload, setSubmitedPayload] = useState<Page2EmailSubmitPayload | null>(null);
  const [fax, setFax] = useState(false);
  const dialogProps = useEmailVerifyDialog();

  const targets = rawTargets.map((target) => ({
    ...target,
    inactive: !target.emailAddress && !(target.faxNumber && fax),
  }));

  useEffect(() => {
    if (!submitedPayload) return undefined;
    let actual = true;
    apiClient
      .post({
        type: APP__POST_EMAIL_ACTION,
        uri: `${basePathname}/${urlQuery?.uc}/${urlQuery?.uf}/action/email`,
        payload: submitedPayload,
        query: { token: urlQuery.token },
        previewResponse: previewMode ? defaultPreviewResponse : undefined,
      })
      .then(([, error]) => {
        if (!actual) return;
        setSubmitedPayload(null);
        if (!error) return;
        (error.errors || []).forEach(({ path, message }) => {
          const key = path.replace(/^email\./, '') as keyof FormValues;
          setError(key, { message });
        });
      });
    return (): void => {
      actual = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitedPayload, urlQuery]);

  useEffect(() => {
    const { needVerify } = postStore?.actions?.email || {};
    if (!!submitedPayload && needVerify && sendDone) dialogProps.onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitedPayload, sendDone, postStore?.actions?.email]);

  const onSubmit = (formValues: FormValues): void => {
    if (staticPageMode) {
      onSubmitEvent();
      return;
    }
    const submitPayload: Page2EmailSubmitPayload = {
      signature: {
        uuid: postStore?.signature?.uuid || '',
      },
      email: {
        ...omit(formValues, ['targets']),
        uuid: postStore?.actions?.email ? postStore.actions.email.uuid : '',
        byFaxAlso: !!features?.fax && !!formValues.byFaxAlso,
      },
      targeting: targets.filter(({ inactive }) => !inactive).map(({ uuid }) => ({ uuid })),
      // eslint-disable-next-line no-underscore-dangle
      _csrf: postStore?._csrf || '',
    };
    setSubmitedPayload(submitPayload);
    const formAction: FormSubmitPage2EmailAction = {
      type: FORM__SUBMIT_PAGE2_EMAIL,
      payload: submitPayload,
    };
    dispatch(formAction);
  };

  const handleFaxCheckbox = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setFax(checked);
  };

  const emailAction = postStore?.actions?.email;
  if (!emailAction) return null;
  const { subject, body, canEditEmail } = emailAction;

  const hasFaxTargets = rawTargets.filter(({ faxNumber }) => !!faxNumber).length > 0;
  const hasEmailTargets = rawTargets.filter(({ emailAddress }) => !!emailAddress).length > 0;
  const showFaxFeature = features?.fax && hasFaxTargets;

  const submitDisabled =
    !!submitedPayload || sendDone || (!hasEmailTargets && !fax && hasFaxTargets);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="email-form">
      <DisabledState active={sendDone}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={6} className={styles.list}>
            <PersonalList
              errorMessage={errors.targets?.message}
              targeting={targets}
              withPhones
              bottom={
                showFaxFeature ? (
                  <FormControlLabel
                    className={styles.faxCheckbox}
                    control={
                      <Checkbox
                        id="byFaxAlso"
                        {...register('byFaxAlso')}
                        onChange={handleFaxCheckbox}
                        value
                        checked={fax}
                      />
                    }
                    label="Send fax if available"
                  />
                ) : null
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="subject" classes={{ root: styles.labelRoot }}>
              Email
            </InputLabel>
            <TextField
              id="subject"
              className={styles.necessaryMargin}
              multiline
              variant="outlined"
              rowsMax={4}
              fullWidth
              defaultValue={formState?.email?.subject || subject}
              inputProps={{ readOnly: !canEditEmail }}
              {...register('subject')}
              error={!!errors.subject}
              helperText={errors.subject?.message}
            />
            <InputLabel
              htmlFor="body"
              classes={{ root: styles.labelMargin }}
              className={styles.necessaryMargin}
            >
              Dear(official)
            </InputLabel>
            <TextField
              id="body"
              variant="outlined"
              multiline
              rowsMax={4}
              fullWidth
              defaultValue={formState?.email?.body || body}
              inputProps={{ readOnly: !canEditEmail }}
              {...register('body')}
              error={!!errors.body}
              helperText={errors.body?.message}
            />
            <Grid className={styles.necessaryMargin} container direction="column">
              <Typography classes={{ root: styles.dearText }} variant="subtitle2" component="span">
                Sincerely,
              </Typography>
              <Typography classes={{ root: styles.dearText }} variant="subtitle2" component="span">
                {page1Form?.firstName} {page1Form?.lastName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DisabledState>
      <Grid container justify="center" className={styles.buttonsBox}>
        <Button type="submit" disabled={submitDisabled} id="submit-email">
          Send message
        </Button>
        <NextStepButton
          active={haveDoneActions}
          handleClick={(): void => setPage(2)}
          id="email-nextstep"
        />
      </Grid>
      <input type="hidden" {...register('targets', { value: targets.length ? 'selected' : '' })} />
      <EmailVerifyDialog {...dialogProps} />
    </form>
  );
};
