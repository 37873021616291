import * as Yup from 'yup';
import gPhoneNumber from 'google-libphonenumber';

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

declare module 'yup' {
  export interface StringSchema {
    phone(countrCode?: string): StringSchema;
  }
}

const YUP_PHONE_METHOD = 'phone';

Yup.addMethod(Yup.string, YUP_PHONE_METHOD, function yupPhone(countryCode = 'US') {
  const errMsg = `Must be a valid phone number for region ${countryCode}`;
  const countryCodes = ['ES'];
  if (countryCode) countryCodes.push(countryCode);
  return this.test(YUP_PHONE_METHOD, errMsg, (value?: string) => {
    if (!value) return true;
    const results = countryCodes.map((currentCountryCode) => {
      try {
        const phoneNumber = phoneUtil.parseAndKeepRawInput(value, currentCountryCode);

        if (!phoneUtil.isPossibleNumber(phoneNumber)) {
          return false;
        }

        const regionCodeFromPhoneNumber = phoneUtil.getRegionCodeForNumber(phoneNumber);

        return (
          regionCodeFromPhoneNumber === currentCountryCode &&
          phoneUtil.isValidNumberForRegion(phoneNumber, regionCodeFromPhoneNumber)
        );
      } catch {
        return false;
      }
    });
    return results.reduce((acc, item) => acc || item, false);
  });
});
