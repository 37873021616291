import { makeStyles } from '@material-ui/core';
import color from 'color';

import { defaultStyles } from 'src/util';
import { GetInit } from 'src/type';

export const useStyles = makeStyles({
  wrapper: { textTransform: 'none', display: 'flex', flexDirection: 'row', fontSize: '16px' },
  tabs: (apiStyles) => {
    const { tabsColor = defaultStyles.tabsColor } = (apiStyles || {}) as GetInit['styles'];
    return {
      boxShadow: `inset 0 -3px 0 0 ${color(tabsColor).fade(0.5).string()}`,
      color: tabsColor,
    };
  },
  root: (apiStyles) => {
    const { tabsColor = defaultStyles.tabsColor } = (apiStyles || {}) as GetInit['styles'];
    return {
      '&.Mui-selected': {
        backgroundColor: color(tabsColor).fade(0.84).string(),
      },
    };
  },
  indicator: (apiStyles) => {
    const { tabsColor = defaultStyles.tabsColor } = (apiStyles || {}) as GetInit['styles'];
    return {
      height: '4px',
      backgroundColor: color(tabsColor).fade(0.4).string(),
    };
  },
});
