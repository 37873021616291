import * as yup from 'yup';

type SchemaType = yup.AnyObjectSchema;

export const makeSchema = (previewMode: boolean): SchemaType => {
  const shape = {
    subject: previewMode ? yup.string() : yup.string().required('Required field').min(10).max(90),
    body: previewMode ? yup.string() : yup.string().required('Required field').min(10).max(4096),
    targets: previewMode ? yup.string() : yup.string().required('Choose at least one'),
  };
  return yup.object().shape(shape);
};
