import { makeStyles } from '@material-ui/core';
import color from 'color';

import { GetInit } from 'src/type';
import { defaultStyles } from 'src/util';

export const useStyles = makeStyles({
  button: (data) => {
    const { buttonColor } = (data || {}) as GetInit['styles'];
    const hoverBackgroundColor = color(buttonColor).fade(0.5).string();
    return {
      width: '253px',
      height: '57px',
      borderRadius: '25px',
      backgroundImage: buttonColor
        ? undefined
        : 'linear-gradient(0deg, rgb(54, 54, 54) 0%, rgb(72, 72, 72) 100%)',
      backgroundColor: buttonColor || undefined,
      fontSize: '19px',
      lineHeight: '25px',
      color: '#fff',
      fontWeight: 600,
      textTransform: 'none',
      ...(buttonColor
        ? {
            '&:hover': {
              backgroundColor: hoverBackgroundColor,
            },
          }
        : {}),
    };
  },
  success: (data) => {
    const { headerColor = defaultStyles.headerColor } = (data || {}) as GetInit['styles'];
    return {
      backgroundImage: 'none',
      backgroundColor: headerColor,
      '&:hover': {
        backgroundColor: headerColor,
      },
    };
  },
});
