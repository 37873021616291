import { FC, useEffect, useState } from 'react';
import { Grid, Box, InputLabel, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { Button, DisabledState, PersonalList } from 'src/blocks';
import { useApiClient } from 'src/hooks';
import {
  Page2TweetSubmitPayload,
  FormSubmitPage2TweetAction,
  FORM__SUBMIT_PAGE2_TWEET,
  APP__POST_TWEET_ACTION,
  RootState,
  FormState,
} from 'src/reducers';
import { defaultPreviewResponse } from 'src/util';

import { makeSchema } from './Tweet.schema';
import { useStyles } from './Tweet.style';
import { Props } from '../Contents.type';
import { NextStepButton } from '../NextStepButton';

const apiUrl = process.env.REACT_APP_API_URL;
const basePathname = process.env.REACT_APP_API_BASE_PATHNAME;

type FormValues = {
  body: string;
  targets: string;
};

const oauthWindowName = 'authenticate_by_oauth';

export const Tweet: FC<Props & { formState: FormState['page2TweetForm'] }> = ({
  postStore,
  setPage,
  sendDone,
  haveDoneActions,
  targets,
  urlQuery,
  formState,
  oauth,
  onSubmitEvent,
  previewMode,
}) => {
  const schema = makeSchema(previewMode);
  const apiClient = useApiClient();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const state = useSelector((reduxstate: RootState) => reduxstate);
  const styles = useStyles(state.app.page1.response?.styles);
  const dispatch = useDispatch();
  const [openedWindow, setOpenedWindow] = useState<Window | null>(null);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (sending || !state.app.oauth || !state.form.page2TweetForm) return undefined;

    let actual = true;
    const { username, twitterSessionId } = state.app.oauth;
    setSending(true);
    apiClient
      .post({
        type: APP__POST_TWEET_ACTION,
        uri: `${basePathname}/${urlQuery.uc}/${urlQuery.uf}/action/twitter`,
        payload: {
          ...state.form.page2TweetForm,
          tweet: {
            ...state.form.page2TweetForm?.tweet,
            username,
            twitterSessionId,
          },
        },
        query: { token: urlQuery.token },
        previewResponse: previewMode ? defaultPreviewResponse : undefined,
      })
      .then(([, error]): void => {
        if (!actual || !error) return;
        setSending(false);
        (error.errors || []).forEach(({ path, message }) => {
          const key = path.replace(/^tweet\./, '') as keyof FormValues;
          setError(key, { message });
        });
      });
    return (): void => {
      actual = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.form.page2TweetForm, state.app.oauth]);

  const onSubmit = (formValues: FormValues): void => {
    if (state.app.staticStepMode) {
      onSubmitEvent();
      return;
    }
    const submitPayload: Page2TweetSubmitPayload = {
      signature: {
        uuid: postStore?.signature?.uuid || '',
      },
      tweet: {
        body: formValues.body,
      },
      targeting: targets.map(({ uuid }) => ({ uuid })),
      // eslint-disable-next-line no-underscore-dangle
      _csrf: postStore?._csrf || '',
    };
    const formAction: FormSubmitPage2TweetAction = {
      type: FORM__SUBMIT_PAGE2_TWEET,
      payload: submitPayload,
    };
    dispatch(formAction);

    const redirect = `${window.location.protocol}//${window.location.host}`;
    const childWindow = window.open(
      previewMode
        ? `${window.location.href}&twitterLoginStatus=ok&username=preview_user&twitterSessionId=preview_session`
        : `${apiUrl}/twitter/login?redirect=${redirect}`,
      oauthWindowName,
      'width=700,height=450,resizable=yes,location',
    );
    setOpenedWindow(childWindow);
  };

  useEffect(() => {
    if (!openedWindow || !oauth) return;
    setOpenedWindow(null);
    openedWindow.close();
  }, [openedWindow, oauth]);

  const twitterAction = postStore?.actions?.twitter;
  if (!twitterAction) return null;
  const { text } = twitterAction;

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="twitter-form">
      <DisabledState active={sendDone}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={6} className={styles.list}>
            <PersonalList errorMessage={errors.targets?.message} targeting={targets} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={styles.tweetBox}>
              <Grid container>
                <InputLabel htmlFor="body" classes={{ root: styles.labelRoot }}>
                  Twitter
                </InputLabel>
                <TextField
                  id="body"
                  className={styles.necessaryMargin}
                  variant="outlined"
                  multiline
                  rows={4}
                  rowsMax={6}
                  fullWidth
                  defaultValue={formState?.tweet.body || text}
                  {...register('body')}
                  error={!!errors.body}
                  helperText={errors.body?.message}
                />
                <Typography variant="subtitle2" component="span" className={styles.handleHint}>
                  Please note that the official&apos;s Twitter handle will be automatically added to
                  the beginning of the tweet
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DisabledState>
      <Grid container justify="center" className={styles.buttonsBox}>
        <Button
          type="submit"
          disabled={(!!state.form.page2TweetForm && !!oauth) || sendDone}
          id="submit-twitter"
        >
          Send Tweet
        </Button>
        <NextStepButton
          active={haveDoneActions}
          handleClick={(): void => setPage(2)}
          id="twitter-nextstep"
        />
      </Grid>
      <input type="hidden" {...register('targets', { value: targets.length ? 'selected' : '' })} />
    </form>
  );
};
