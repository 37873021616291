import { useState } from 'react';

type HookResult = {
  open: boolean;
  onClose(): void;
  onOpen(): void;
};

export const useEmailVerifyDialog = (): HookResult => {
  const [open, setOpen] = useState(false);
  const onOpen = (): void => {
    setOpen(true);
  };

  const onClose = (): void => {
    setOpen(false);
  };

  return {
    open,
    onClose,
    onOpen,
  };
};
