import { FC, ChangeEvent } from 'react';
import {
  Accordion as AccordionMUI,
  Box,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { AppState } from 'src/reducers';

import { useStyles } from './Accordion.css';
import { ActionContent } from '../../Content/Pages/Page2/Page2.type';

type Props = {
  appState: AppState;
  expanded: number | false;
  makeChangeAccordionHandler: (
    newValue: number,
  ) => (event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => void;
  items: ActionContent[];
};

export const Accordion: FC<Props> = ({ appState, expanded, makeChangeAccordionHandler, items }) => {
  const apiStyles = appState.page1.response?.styles;
  const styles = useStyles(apiStyles);
  return (
    <Box pt={3}>
      {items.map(({ id, icon, element, label }, index) => {
        const isExpanded = index === expanded;
        return (
          <AccordionMUI
            id={id}
            classes={{ root: styles.accordion }}
            elevation={0}
            expanded={isExpanded}
            square
            onChange={makeChangeAccordionHandler(index)}
            key={label}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              classes={{
                root: styles.summaryRoot,
                expanded: styles.summaryExpanded,
                content: styles.summaryContent,
                expandIcon: styles.expandIcon,
              }}
            >
              {icon}
              <Box ml={1}>
                <Typography
                  className={
                    isExpanded ? styles.summaryTypographyExpanded : styles.summaryTypography
                  }
                >
                  {label}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails classes={{ root: styles.detailsRoot }}>{element}</AccordionDetails>
          </AccordionMUI>
        );
      })}
    </Box>
  );
};
