import { AxiosRequestConfig } from 'axios';
import {
  InitQueryParams,
  ResponseWithErrors,
  GetInit,
  PostStore,
  PostEmailAction,
  PostPhoneAction,
  PostTwitterAction,
  PostFinalize,
  StaticModeInit,
} from 'src/type';
import { NetworkErrorPayload } from '../networkError/networkError.type';

export const APP__BEGIN_REQUEST = 'APP__BEGIN_REQUEST';
export const APP__GET_INIT = 'APP__GET_INIT';
export const APP__GET_PREVIEW_INIT = 'APP__GET_PREVIEW_INIT';
export const APP__POST_STORE = 'APP__POST_STORE';
export const APP__POST_EMAIL_ACTION = 'APP__POST_EMAIL_ACTION';
export const APP__POST_PHONE_ACTION = 'APP__POST_PHONE_ACTION';
export const APP__POST_FINILAZE = 'APP__POST_FINILAZE';
export const APP__OAUTH_LOGIN_ACTION = 'APP__OAUTH_LOGIN_ACTION';
export const APP__POST_TWEET_ACTION = 'APP__POST_TWEET_ACTION';
export const APP__SET_UID_ACTION = 'APP__SET_UID_ACTION';

export type AppState = {
  securityError: NetworkErrorPayload | null;
  requestsCount: number;
  urlQuery: InitQueryParams;
  uid: string | null;
  page1: {
    response?: GetInit;
    error?: ResponseWithErrors;
  };
  page2: {
    response?: PostStore;
    error?: ResponseWithErrors;
  };
  page2Email: {
    response?: PostEmailAction;
    error?: ResponseWithErrors;
  };
  page2Phone: {
    response?: PostPhoneAction;
    error?: ResponseWithErrors;
  };
  page2Twitter: {
    response?: PostTwitterAction;
    error?: ResponseWithErrors;
  };
  finalizePage: {
    response?: PostFinalize;
    error?: ResponseWithErrors;
  };
  oauth: {
    twitterLoginStatus: string;
    username: string;
    twitterSessionId: string;
  } | null;
  adminFrame: boolean;
  previewMode: boolean;
  staticStepMode: boolean;
};
type ResponseActionType =
  | typeof APP__GET_INIT
  | typeof APP__GET_PREVIEW_INIT
  | typeof APP__POST_STORE
  | typeof APP__POST_EMAIL_ACTION
  | typeof APP__POST_PHONE_ACTION
  | typeof APP__POST_TWEET_ACTION
  | typeof APP__POST_FINILAZE;

export type BeginRequestAction = {
  type: typeof APP__BEGIN_REQUEST;
};

type ResponseUnion =
  | GetInit
  | StaticModeInit
  | PostStore
  | PostEmailAction
  | PostPhoneAction
  | PostTwitterAction
  | PostFinalize;

export type RequestAction = {
  type: ResponseActionType;
  uri: string;
  payload?: AxiosRequestConfig['data'];
  query?: AxiosRequestConfig['params'];
  auth?: AxiosRequestConfig['auth'];
  previewResponse?: ResponseUnion;
};

export type ResponseAction<TResponse extends ResponseUnion> = {
  type: ResponseActionType;
  response?: TResponse;
  error?: ResponseWithErrors;
  networkErrorPayload: NetworkErrorPayload;
  requestPayload: { targeting: { uuid: string }[] };
};

export type OauthLoginAction = {
  type: typeof APP__OAUTH_LOGIN_ACTION;
  payload: AppState['oauth'];
};

export type SetUidAction = {
  type: typeof APP__SET_UID_ACTION;
  payload: AppState['uid'];
};
