import { useEffect } from 'react';

export const useLytics = (handler: (uid: string) => void): void => {
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    const tick = (): void => {
      if ('jstag' in window) {
        clearInterval(interval);
        window.jstag.getid((id: string | null) => {
          if (id) handler(id);
        });
      }
    };
    interval = setInterval(tick, 64);

    return (): void => clearTimeout(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

declare global {
  interface Window {
    jstag: {
      getid: (callback: (id: string | null) => void) => void;
    };
  }
}
