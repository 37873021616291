import pick from 'lodash/pick';
import TagManager from 'react-gtm-module';

import {
  GetInit,
  PostStore,
  PostEmailAction,
  PostPhoneAction,
  PostTwitterAction,
  PostFinalize,
  StaticModeInit,
} from 'src/type';
import { getUrlQuery, trackEvent } from 'src/util';
import { getStaticModeData } from 'src/util/getStaticModeData';

import {
  AppState,
  APP__BEGIN_REQUEST,
  APP__GET_INIT,
  APP__POST_STORE,
  APP__POST_EMAIL_ACTION,
  APP__POST_PHONE_ACTION,
  APP__POST_TWEET_ACTION,
  ResponseAction,
  BeginRequestAction,
  OauthLoginAction,
  APP__OAUTH_LOGIN_ACTION,
  SetUidAction,
  APP__SET_UID_ACTION,
  APP__POST_FINILAZE,
  APP__GET_PREVIEW_INIT,
} from './app.type';

const urlQuery = getUrlQuery();

const initState: AppState = {
  securityError: null,
  requestsCount: 0,
  uid: null,
  page1: {},
  page2: {},
  page2Email: {},
  page2Phone: {},
  page2Twitter: {},
  finalizePage: {},
  urlQuery,
  oauth: null,
  adminFrame: urlQuery.adminPreview === 'true',
  previewMode: !!urlQuery.token,
  staticStepMode: getStaticModeData().staticStepMode,
};

const requestsCountAdd = (state: AppState, diff: number): AppState => ({
  ...state,
  requestsCount: state.requestsCount + diff,
});

const initGTM = (state: AppState): void => {
  const { gtmId } = state.page1.response || {};
  if (gtmId) TagManager.initialize({ gtmId });
};

export const app = (
  state: AppState = initState,
  action:
    | BeginRequestAction
    | ResponseAction<GetInit>
    | ResponseAction<StaticModeInit>
    | ResponseAction<PostStore>
    | ResponseAction<PostEmailAction>
    | ResponseAction<PostPhoneAction>
    | ResponseAction<PostTwitterAction>
    | ResponseAction<PostFinalize>
    | OauthLoginAction
    | SetUidAction,
): AppState => {
  if (state.urlQuery.twitterLoginStatus && /^@@/.test(action.type)) {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'oauth_login',
          payload: state.urlQuery,
        },
        '*',
      );
    } else {
      return {
        ...state,
        securityError: {
          title: 'Security Error',
          message: 'Please configure frame security policy.',
        },
      };
    }
    window.close();
    return state;
  }
  switch (action.type) {
    case APP__BEGIN_REQUEST:
      return requestsCountAdd(state, 1);
    case APP__GET_INIT: {
      const initAction = action as ResponseAction<GetInit>;
      const nextState = {
        ...requestsCountAdd(state, -1),
        page1: pick(initAction, ['response', 'error']),
      };
      if (!state.urlQuery.twitterLoginStatus) {
        initGTM(nextState);
        trackEvent('initialized');
      }
      return nextState;
    }
    case APP__GET_PREVIEW_INIT: {
      const initAction = action as ResponseAction<StaticModeInit>;
      const nextState = {
        ...requestsCountAdd(state, -1),
        page1: { response: initAction.response?.init, error: initAction.error },
        page2: { response: initAction.response?.store, error: initAction.error },
      };
      return nextState;
    }
    case APP__POST_STORE: {
      trackEvent('step_2_reached');
      return {
        ...requestsCountAdd(state, -1),
        page2: pick(action as ResponseAction<PostStore>, ['response', 'error']),
      };
    }
    case APP__POST_EMAIL_ACTION:
      return {
        ...requestsCountAdd(state, -1),
        page2Email: pick(action as ResponseAction<PostEmailAction>, ['response', 'error']),
      };
    case APP__POST_PHONE_ACTION:
      return {
        ...requestsCountAdd(state, -1),
        page2Phone: {
          ...state.page2Phone,
          ...pick(action as ResponseAction<PostPhoneAction>, ['response', 'error']),
          [action.requestPayload.targeting[0].uuid]: true,
        },
      };
    case APP__POST_TWEET_ACTION:
      return {
        ...requestsCountAdd(state, -1),
        page2Twitter: pick(action as ResponseAction<PostTwitterAction>, ['response', 'error']),
        oauth: null,
      };
    case APP__POST_FINILAZE:
      return {
        ...requestsCountAdd(state, -1),
        finalizePage: pick(action as ResponseAction<PostFinalize>, ['response', 'error']),
      };
    case APP__OAUTH_LOGIN_ACTION:
      return action.payload?.twitterLoginStatus === 'ok'
        ? {
            ...state,
            oauth: action.payload,
          }
        : state;
    case APP__SET_UID_ACTION:
      return { ...state, uid: action.payload };
    default:
      return state;
  }
};
