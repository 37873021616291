import { FC } from 'react';

import { makeStyles } from '@material-ui/core';

type Props = {
  active: boolean;
};

const useStyles = makeStyles(() => ({
  disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
}));

export const DisabledState: FC<Props> = ({ active, children }): JSX.Element => {
  const styles = useStyles();
  return <div className={active ? styles.disabled : undefined}>{children}</div>;
};
