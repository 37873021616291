import { makeStyles, StepIconProps } from '@material-ui/core';

const useStyles = makeStyles({
  step: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48px',
    height: '48px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '50%',
    fontSize: '36px',
  },
});

export const StepIconComponent = ({ icon }: StepIconProps): JSX.Element => {
  const styles = useStyles();
  return <div className={styles.step}>{icon}</div>;
};
