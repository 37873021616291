import { TabSetAction, TAB__SET, TabState } from './tab.type';

export const tab = (state: TabState = 0, action: TabSetAction): TabState => {
  switch (action.type) {
    case TAB__SET:
      return action.payload;
    default:
      return state;
  }
};
