import { createMuiTheme } from '@material-ui/core';
import color from 'color';

import { defaultStyles } from 'src/util';
import { GetInit } from './type';

export const getTheme = (styles?: GetInit['styles']): ReturnType<typeof createMuiTheme> =>
  createMuiTheme({
    typography: {
      fontFamily: styles?.fontFamily || defaultStyles.fontFamily,
    },
    overrides: {
      MuiTypography: {
        root: {
          color: styles?.fontColor,
        },
      },
      MuiButton: {
        contained: {
          '&$disabled': {
            opacity: 0.4,
            color: styles?.buttonTextColor,
            backgroundColor: styles?.buttonColor
              ? color(styles.buttonColor).fade(0.5).string()
              : undefined,
          },
        },
        label: {
          color: styles?.buttonTextColor,
        },
      },
      MuiInputBase: {
        root: {
          backgroundColor: '#fff',
        },
      },
      MuiTextField: {
        root: {
          borderRadius: '4px',
        },
      },
      MuiOutlinedInput: {
        root: {
          '&$focused $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 1,
          },
        },
        input: {
          color: '#666',
          paddingTop: '16px',
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          color: styles?.fontColor,
          '&$checked': {
            color: styles?.fontColor,
          },
        },
      },
      MuiInputLabel: {
        outlined: {
          '&$shrink': {
            color: '#666',
            transform: 'translate(16px, 8px) scale(0.6)',
          },
        },
      },
      MuiStepLabel: {
        root: {
          color: styles?.fontColor,
        },
      },
      MuiStepConnector: {
        line: {
          borderColor: styles?.fontColor,
        },
      },
    },
    props: {
      MuiOutlinedInput: {
        notched: false,
      },
    },
  });
