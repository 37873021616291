import { makeStyles, Theme } from '@material-ui/core';

import { GetInit } from 'src/type';
import { defaultStyles } from 'src/util';

export const useStyles = makeStyles((theme: Theme) => ({
  handleHint: (data) => {
    const { fontColor = defaultStyles.fontColor } = (data || {}) as GetInit['styles'];
    return {
      color: fontColor,
      marginLeft: theme.spacing(2),
    };
  },
  tweetBox: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
    },
  },
  list: {
    position: 'relative',
  },
  necessaryMargin: {
    marginTop: theme.spacing(1),
  },
  labelRoot: (data) => {
    const { fontColor = defaultStyles.fontColor } = (data || {}) as GetInit['styles'];
    return {
      color: fontColor,
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.6',
    };
  },
  buttonsBox: {
    marginTop: 16,
    marginBottom: 4,
    '& > button': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('xs')]: {
        marginRight: theme.spacing(1),
      },
      marginRight: 0,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));
