import { FC, ReactNode } from 'react';
import { Box } from '@material-ui/core';

type Props = {
  children?: ReactNode;
  index: number;
  value: number;
};

export const TabPanel: FC<Props> = ({ children, index, value, ...rest }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...rest}
  >
    {value === index && <Box pt={3}>{children}</Box>}
  </div>
);
