let height = 0;

export const observeHeight = (node: HTMLElement): void => {
  const trackHeight = () => {
    const newHeight = node.scrollHeight;
    if (height !== newHeight) {
      height = newHeight;
      const targetWindow = window.opener || window.parent;
      if (targetWindow) {
        targetWindow.postMessage({ height }, '*');
      }
    }
  };
  const observer = new MutationObserver(trackHeight);
  observer.observe(document.body, { attributes: true, childList: true, subtree: true });
  trackHeight();
};
