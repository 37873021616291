export type NetworkErrorPayload = {
  title: string;
  message: string;
};

export type NetworkErrorState = false | NetworkErrorPayload;

export const NETWORK_ERROR__DISMIS = 'NETWORK_ERROR__DISMIS';
export const NETWORK_ERROR__SET = 'NETWORK_ERROR__SET';

export type NetworkErrorDismisAction = {
  type: typeof NETWORK_ERROR__DISMIS;
};

export type NetworkErrorSetAction = {
  type: typeof NETWORK_ERROR__SET;
  payload: NetworkErrorPayload;
};
