import * as yup from 'yup';
import '../../../yup-phone';

type SchemaType = yup.AnyObjectSchema;

export const makeSchema = (previewMode: boolean): SchemaType => {
  const shape = {
    script: previewMode
      ? yup.string().max(5000, 'Must be at most 5000 characters')
      : yup
          .string()
          .min(10, 'Must be at least 10 characters')
          .max(5000, 'Must be at most 5000 characters')
          .required('Required field'),
    targets: previewMode ? yup.string() : yup.string().required('Choose one'),
  };
  return yup.object().shape(shape);
};
