import { Grid, Stepper as CoreSteper, Step, StepLabel, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { StepIconComponent } from './StepIconComponent';
import { StepperConnector } from './StepperConnector';

type Props = {
  page: number;
};

const steps = ['1', '2', '3'];
const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
    padding: '24px 0px',
  },
  clearPadding: {
    padding: 0,
  },
  disabled: {
    opacity: 0.3,
    userSelect: 'none',
  },
});

export const Stepper: FC<Props> = ({ page }) => {
  const styles = useStyles();
  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={6}>
        <CoreSteper className={styles.root} activeStep={page} connector={<StepperConnector />}>
          {steps.map((label: string) => (
            <Step classes={{ horizontal: styles.clearPadding }} key={label}>
              <StepLabel
                classes={{ iconContainer: styles.clearPadding, disabled: styles.disabled }}
                StepIconComponent={StepIconComponent}
                StepIconProps={{ icon: label }}
              />
            </Step>
          ))}
        </CoreSteper>
      </Grid>
    </Grid>
  );
};
