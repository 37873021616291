import { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import { Button } from '../../../../../blocks';

type Props = {
  open: boolean;
  onClose(): void;
};

export const EmailVerifyDialog: FC<Props> = (props) => {
  const { open, onClose } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Notice</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please check your inbox and validate your email to finalize reaching the selected targets
          by email.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
