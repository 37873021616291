import { trackEvent } from 'src/util';
import { getStaticModeData } from 'src/util/getStaticModeData';
import { PageSetAction, PAGE__SET } from './page.type';

const { step } = getStaticModeData();
const initState: number = step;

export const page = (state = initState, action: PageSetAction): number => {
  switch (action.type) {
    case PAGE__SET: {
      if (action.payload === 2 && action.payload !== state) trackEvent('finalized');
      return action.payload;
    }
    default:
      return state;
  }
};
