import { FC, ChangeEvent } from 'react';
import { Hidden, makeStyles, Typography } from '@material-ui/core';
import { MailOutline, Twitter, Call } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';

import { RootState, TAB__SET } from 'src/reducers';
import { Accordion } from 'src/blocks';
import { GetInit } from 'src/type';
import { defaultStyles } from 'src/util';

import { ActionContent, Props } from './Page2.type';
import { Tabs } from './Tabs';
import { Email, Tweet, Call as CallContent } from './Contents';

const useStyles = makeStyles({
  title: (data) => {
    const { fontColor = defaultStyles.fontColor } = (data || {}) as GetInit['styles'];
    return {
      fontSize: '18px',
      color: fontColor,
    };
  },
});

export const Page2: FC<Props> = ({ appState, formState, setPage, onSubmitEvent }) => {
  const {
    page1: { response: getInit },
    page2: { response: postStore },
    page2Email: { response: postEmailAction },
    page2Phone: { response: postPhoneAction, ...restPage2Phone },
    page2Twitter: { response: postTweetAction },
    urlQuery,
    oauth,
    previewMode,
  } = appState;

  const styles = useStyles(getInit?.styles);
  const tab = useSelector((state: RootState) => state.tab);
  const dispatch = useDispatch();
  const isAdvocateDevice =
    (postStore?.actions?.phoneCall && postStore?.actions?.phoneCall?.phoneActionType) ===
    'ADVOCATE_DEVICE';

  const { actions, targeting: rawTargeting } = postStore || {};
  const targeting = rawTargeting || [];
  const actionsDone = [!!postEmailAction, !!postPhoneAction, !!postTweetAction].filter(
    (item) => item,
  );
  const actionProps = {
    setPage: (nextPage: number): void => {
      const redirect = getInit?.redirectionUrl || '';
      if (redirect?.length) {
        const redirectWithProtocol = /^http/.test(redirect) ? redirect : `//${redirect}`;
        const targetWindow = appState.adminFrame ? window : window.parent;
        targetWindow.location.href = redirectWithProtocol;
        return;
      }
      setPage(nextPage);
    },
    postStore,
    urlQuery,
    haveDoneActions: actionsDone.length > 0,
    oauth,
    previewMode,
  };

  const targetsWithEmailOrFaxes = targeting.filter(
    ({ emailAddress, faxNumber }) => !!emailAddress || (faxNumber && getInit?.features?.fax),
  );
  const targetsAllOrWithoutFax = targeting.filter(({ emailAddress, faxNumber }) =>
    getInit?.features?.fax ? true : !faxNumber || !!emailAddress,
  );
  const targetsWithPhone = targeting
    .filter(({ phoneNumber }) => phoneNumber)
    .map((target) => ({
      ...target,
      sended: Boolean(restPage2Phone[target.uuid]),
    }));
  const targetsWithTwitter = targeting.filter(({ twitterHandle }) => twitterHandle);

  const actionsContent = [
    actions?.email &&
      targetsWithEmailOrFaxes.length > 0 && {
        id: 'email-tab',
        element: (
          <Email
            {...actionProps}
            formState={formState.page2EmailForm}
            sendDone={!!postEmailAction}
            targets={targetsAllOrWithoutFax}
            onSubmitEvent={onSubmitEvent}
          />
        ),
        label: 'Email',
        icon: <MailOutline fontSize="large" />,
      },
    actions?.twitter &&
      targetsWithTwitter.length > 0 && {
        id: 'twitter-tab',
        element: (
          <Tweet
            {...actionProps}
            formState={formState.page2TweetForm}
            sendDone={!!postTweetAction}
            targets={targetsWithTwitter}
            onSubmitEvent={onSubmitEvent}
          />
        ),
        label: 'Tweet',
        icon: <Twitter fontSize="large" />,
      },
    actions?.phoneCall &&
      targetsWithPhone.length > 0 && {
        id: 'phonecall-tab',
        element: (
          <CallContent
            {...actionProps}
            isAdvocateDevice={isAdvocateDevice}
            formState={formState.page2PhoneForm}
            sendDone={!!postPhoneAction}
            targets={targetsWithPhone}
            onSubmitEvent={onSubmitEvent}
          />
        ),
        label: 'Call',
        icon: <Call fontSize="large" />,
      },
  ].filter(Boolean) as ActionContent[];

  const hasUnsentPhoneActions = isAdvocateDevice
    ? targetsWithPhone.some(({ sended }) => !sended)
    : false;

  const shouldSkip =
    !actionsContent.length || !targeting?.length || actionsDone.length === actionsContent.length;

  if (shouldSkip && !hasUnsentPhoneActions) {
    actionProps.setPage(2);
    return null;
  }

  // const noData = !actionsContent.length || !targeting || !targeting.length;
  // const allActionsDone = actionsDone.length === actionsContent.length;

  // if (noData || allActionsDone) {
  //   actionProps.setPage(2);
  //   return null;
  // }

  const handleChangeTabs = (event: ChangeEvent<Record<string, unknown>>, payload: number): void => {
    if (tab !== payload) dispatch({ type: TAB__SET, payload });
  };

  const makeChangeAccordionHandler =
    (nextTab: number) =>
    (event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean): void => {
      dispatch({ type: TAB__SET, payload: isExpanded ? nextTab : null });
    };

  return (
    <>
      <Typography className={styles.title} id="step2-title">
        {getInit?.form.step2.text}
      </Typography>
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <Accordion
          items={actionsContent}
          expanded={typeof tab === 'number' ? tab : false}
          makeChangeAccordionHandler={makeChangeAccordionHandler}
          appState={appState}
        />
      </Hidden>
      <Hidden only={['xs']}>
        <Tabs
          items={actionsContent}
          value={tab || 0}
          handleChange={handleChangeTabs}
          appState={appState}
        />
      </Hidden>
    </>
  );
};
