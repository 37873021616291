import { FC, useEffect } from 'react';
import { Grid, makeStyles, IconButton, Typography } from '@material-ui/core';
import { Facebook, Twitter, LinkedIn, Email, WhatsApp } from '@material-ui/icons';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from 'react-share';

import { GetInit } from 'src/type';
import { defaultPreviewResponse, defaultStyles, trackEvent } from 'src/util';
import { useApiClient } from 'src/hooks';
import { APP__POST_FINILAZE } from 'src/reducers';

import { Props } from './Page3.type';

const useStyles = makeStyles((theme) => ({
  title: (data) => {
    const { fontColor = defaultStyles.fontColor } = (data || {}) as GetInit['styles'];
    return {
      fontSize: '20px',
      marginTop: '16px',
      color: fontColor,
    };
  },
  buttonRoot: (data) => {
    const { fontColor } = (data || {}) as GetInit['styles'];
    return {
      color: fontColor || defaultStyles.fontColor,
      [theme.breakpoints.down('xs')]: {
        padding: '6px',
      },
    };
  },
}));

const basePathname = process.env.REACT_APP_API_BASE_PATHNAME;

export const Page3: FC<Props> = ({ appState }): JSX.Element => {
  const apiClient = useApiClient();
  const {
    page1: { response },
    page2,
    urlQuery,
    previewMode,
  } = appState;
  const { socialShare, form } = response || {};
  const { link, tweetText, tweetVia } = socialShare || {};
  const disabled = !link;
  const shareUrl = link || 'https://google.com';
  const shareText = tweetText || 'Share text';
  const shareVia = tweetVia || '';
  const { uc, uf } = urlQuery || {};

  const styles = useStyles(response?.styles);

  const makeShareHandler = (shareType: string) => (): void => {
    trackEvent(`Share: ${shareType}`);
  };

  useEffect(() => {
    if (appState.staticStepMode) {
      return;
    }
    apiClient.post({
      type: APP__POST_FINILAZE,
      uri: `${basePathname}/${uc}/${uf}/finalize`,
      payload: {
        // eslint-disable-next-line no-underscore-dangle
        _csrf: response?._csrf || '',
        signature: {
          uuid: page2?.response?.signature?.uuid,
        },
      },
      query: { token: urlQuery.token },
      previewResponse: previewMode ? defaultPreviewResponse : undefined,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container justify="center" direction="column" spacing={2}>
      <Grid item>
        <Typography className={styles.title} align="center" id="step3-title">
          {form?.step3.text}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container justify="center" wrap="nowrap">
          <Grid item>
            <FacebookShareButton
              id="facebook-share"
              disabled={disabled}
              url={shareUrl}
              quote={shareText}
              className={styles.buttonRoot}
              beforeOnClick={makeShareHandler('Facebook')}
            >
              <IconButton className={styles.buttonRoot} component="span">
                <Facebook fontSize="large" />
              </IconButton>
            </FacebookShareButton>
          </Grid>
          <Grid item>
            <TwitterShareButton
              id="twitter-share"
              disabled={disabled}
              url={shareUrl}
              title={shareText}
              via={shareVia}
              beforeOnClick={makeShareHandler('Twitter')}
            >
              <IconButton className={styles.buttonRoot} component="span">
                <Twitter fontSize="large" />
              </IconButton>
            </TwitterShareButton>
          </Grid>
          <Grid item>
            <LinkedinShareButton
              id="linkedin-share"
              disabled={disabled}
              url={shareUrl}
              title={shareText}
              beforeOnClick={makeShareHandler('LinkedIn')}
            >
              <IconButton className={styles.buttonRoot} component="span">
                <LinkedIn fontSize="large" />
              </IconButton>
            </LinkedinShareButton>
          </Grid>
          <Grid item>
            <EmailShareButton
              id="email-share"
              disabled={disabled}
              url={shareUrl}
              subject={shareText}
              className={styles.buttonRoot}
              beforeOnClick={makeShareHandler('Email')}
            >
              <IconButton className={styles.buttonRoot} component="span">
                <Email fontSize="large" />
              </IconButton>
            </EmailShareButton>
          </Grid>
          <Grid item>
            <WhatsappShareButton
              id="whatsapp-share"
              disabled={disabled}
              url={shareUrl}
              title={shareText}
              windowWidth={700}
              windowHeight={600}
              beforeOnClick={makeShareHandler('WhatsApp')}
            >
              <IconButton className={styles.buttonRoot} component="span">
                <WhatsApp fontSize="large" />
              </IconButton>
            </WhatsappShareButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
