import { PostEmailAction, PostFinalize, PostPhoneAction, PostTwitterAction } from 'src/type';

export const defaultPreviewResponse:
  | PostEmailAction
  | PostPhoneAction
  | PostTwitterAction
  | PostFinalize = {
  status: 200,
  statusMessage: 'Ok',
  errors: undefined,
  errorMessage: undefined,
};
