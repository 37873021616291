import { Button as ButtonMUI } from '@material-ui/core';
import { FC, ButtonHTMLAttributes } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/reducers';

import { useStyles } from './Button.css';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  success?: boolean;
};

export const Button: FC<Props> = ({
  children,
  disabled,
  onClick,
  type,
  success,
  id,
}): JSX.Element => {
  const { response } = useSelector(({ app }: RootState) => app.page1);
  const styles = useStyles(response?.styles);
  return (
    <ButtonMUI
      id={id}
      onClick={onClick}
      className={`${styles.button} ${success ? styles.success : ''}`}
      disabled={disabled}
      variant="contained"
      size="large"
      type={type}
    >
      {children}
    </ButtonMUI>
  );
};
