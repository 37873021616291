import { withStyles, StepConnector } from '@material-ui/core';

export const StepperConnector = withStyles({
  disabled: {
    opacity: 0.3,
  },
  lineHorizontal: {
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },
})(StepConnector);
