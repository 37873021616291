import { makeStyles, Theme } from '@material-ui/core';

import { GetInit } from 'src/type';
import { defaultStyles } from 'src/util';

export const useStyles = makeStyles((theme: Theme) => ({
  callBox: {
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  list: {
    position: 'relative',
  },
  necessaryMargin: {
    marginTop: theme.spacing(1),
  },
  labelRoot: (data) => {
    const { fontColor = defaultStyles.fontColor } = (data || {}) as GetInit['styles'];
    return {
      color: fontColor,
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.6',
    };
  },
  title: (data) => {
    const { fontColor = defaultStyles.fontColor } = (data || {}) as GetInit['styles'];
    return {
      color: fontColor,
      [theme.breakpoints.down('xs')]: { fontSize: '14px' },
      [theme.breakpoints.up('sm')]: { minHeight: '8rem' },
    };
  },
  callScript: {
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  buttonsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    '& > button': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('xs')]: {
        marginRight: theme.spacing(1),
      },
      marginRight: 0,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));
