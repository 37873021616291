import { FC } from 'react';
import { Container } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { NETWORK_ERROR__SET, AppState, FormState } from 'src/reducers';
import { DisabledState, Stepper } from 'src/blocks';
import { Page1, Page2, Page3 } from './Pages';

const Pages = [Page1, Page2, Page3];

type Props = {
  appState: AppState;
  formState: FormState;
  loading: boolean;
  page: number;
  setPage: (nextPage: number) => void;
};

export const Content: FC<Props> = ({ appState, formState, loading, setPage, page }) => {
  const dispatch = useDispatch();
  const Page = Pages[page];

  const reactOnSubmit = () => {
    if (!appState.staticStepMode) return;
    dispatch({
      type: NETWORK_ERROR__SET,
      payload: {
        title: 'Wrong state error',
        message: 'Submit button does not work in static preview mode',
      },
    });
  };
  const handleSetPage = (nextPage: number) => {
    if (!appState.staticStepMode) {
      setPage(nextPage);
    }
  };

  return (
    <DisabledState active={loading}>
      <Container maxWidth="md">
        <Stepper page={page} />
        <Page
          appState={appState}
          formState={formState}
          setPage={handleSetPage}
          loading={loading}
          onSubmitEvent={reactOnSubmit}
        />
      </Container>
    </DisabledState>
  );
};
