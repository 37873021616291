import * as yup from 'yup';

type SchemaType = yup.AnyObjectSchema;

export const makeSchema = (previewMode: boolean): SchemaType => {
  const shape = {
    body: previewMode
      ? yup.string().max(263, 'Must be at most 263 characters')
      : yup
          .string()
          .required('Required field')
          .min(8, 'Must be at least 8 characters')
          .max(263, 'Must be at most 263 characters'),
    targets: previewMode ? yup.string() : yup.string().required('Choose at least one'),
  };
  return yup.object().shape(shape);
};
