/* eslint-disable react/no-array-index-key */
import { FC, ReactNode } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  FormHelperText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

import { TargetingItem, PhotoCroping } from 'src/type';

import { useStyles } from './PersonalList.css';

type ExtendedTarget = TargetingItem & {
  sended?: boolean;
  selected?: boolean;
  inactive?: boolean;
};

type Props = {
  isAdvocateDevice?: boolean;
  errorMessage?: string;
  onSelect?: (target: ExtendedTarget | null) => void;
  targeting?: ExtendedTarget[] | null;
  withPhones?: boolean;
  bottom?: ReactNode;
};

const displayWidth = 48;
const displayHeight = 48;

const getCropedAvatarStyles = (
  originUrl: string,
  { x, y, width, height, origHeight, origWidth }: PhotoCroping,
): Record<string, string | number> => {
  const ratio = height / width;
  const imageScale = ratio > 1 ? displayWidth / width : displayHeight / height;

  const top =
    ratio > 1 ? -y * imageScale - (imageScale * height - displayHeight) / 2 : -y * imageScale;

  const left =
    ratio > 1 ? -x * imageScale : -x * imageScale - (imageScale * width - displayWidth) / 2;

  return {
    height: displayHeight,
    width: displayWidth,
    backgroundImage: `url('${originUrl}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${origWidth * imageScale}px ${origHeight * imageScale}px`,
    backgroundPosition: `${left}px ${top}px`,
  };
};

export const PersonalList: FC<Props> = ({
  isAdvocateDevice,
  errorMessage,
  onSelect,
  targeting,
  withPhones,
  bottom,
}) => {
  const styles = useStyles();

  const makeClickHandler = (target: ExtendedTarget) => (): void => {
    if (!onSelect) return;
    onSelect(target.selected ? null : target);
  };

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.box} ${errorMessage ? 'error' : ''}`}>
        <div className={styles.subboxWrap}>
          <div className={styles.subbox}>
            <List disablePadding className={styles.list} component="nav" id="targeting">
              {(targeting || []).map((target, key) => {
                const {
                  uuid,
                  faxNumber,
                  firstName,
                  lastName,
                  photoOriginUrl,
                  photoCropping,
                  phoneNumber,
                  sended,
                  selected,
                  inactive,
                } = target;
                let secondaryNodes;
                if (withPhones && (phoneNumber || faxNumber)) {
                  const phoneText = phoneNumber ? `tel: ${phoneNumber}` : undefined;
                  const faxText = faxNumber ? `fax: ${faxNumber}` : undefined;
                  secondaryNodes = [phoneText, faxText].filter(Boolean).map((text) => (
                    <span key={text} className={styles.secondaryPhonesText}>
                      {text}
                    </span>
                  ));
                  if (inactive && secondaryNodes.length === 1) {
                    secondaryNodes.push(
                      <span key="not_reach" className={styles.secondaryPhonesText}>
                        Not reachable
                      </span>,
                    );
                  }
                } else if (inactive) {
                  secondaryNodes = <span>Not reachable</span>;
                }
                return (
                  <ListItem
                    ContainerProps={{ id: `target-${key}` }}
                    disabled={inactive || !onSelect || (sended && !isAdvocateDevice)}
                    button
                    className={`target-uuid-${uuid}`}
                    classes={{
                      root: styles.itemRoot,
                      disabled: sended ? styles.disabledBySend : styles.disabled,
                      gutters: styles.gutters,
                      container: clsx(inactive && [styles.inactive, 'inactiveState']),
                    }}
                    key={key}
                    onClick={makeClickHandler(target)}
                  >
                    <ListItemAvatar>
                      {photoOriginUrl && photoCropping ? (
                        <div
                          className={styles.avatarRoot}
                          style={getCropedAvatarStyles(photoOriginUrl, photoCropping)}
                        />
                      ) : (
                        <Avatar
                          classes={{ root: styles.avatarRoot }}
                          variant="square"
                          src={photoOriginUrl || undefined}
                        />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      classes={{ primary: styles.primaryTextList }}
                      primary={`${firstName} ${lastName}`}
                      secondary={secondaryNodes}
                    />
                    {(selected || !onSelect) && (
                      <ListItemSecondaryAction>
                        {inactive ? (
                          <Tooltip title="Not reachable">
                            <BlockIcon />
                          </Tooltip>
                        ) : (
                          <CheckCircleIcon
                            className={sended ? styles.disabledCheckCircle : styles.checkCircle}
                          />
                        )}
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </div>
          {errorMessage ? (
            <FormHelperText error className={styles.error}>
              {errorMessage}
            </FormHelperText>
          ) : null}
          {bottom || null}
        </div>
      </div>
    </div>
  );
};
