import pick from 'lodash/pick';
import { parse } from 'query-string';

import { InitQueryParams } from 'src/type';

export const getUrlQuery = (): InitQueryParams => {
  const { search } = location; // eslint-disable-line no-restricted-globals
  return pick<InitQueryParams>(parse(search), [
    'uc',
    'uf',
    'address',
    'city',
    'zip',
    'first_name',
    'last_name',
    'email',
    'phone',
    'utm_content',
    'utm_term',
    'utm_medium',
    'utm_campaign',
    'utm_source',
    'username',
    'twitterSessionId',
    'twitterLoginStatus',
    'adminPreview',
    'staticStep',
    'token',
  ]);
};
