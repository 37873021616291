import { FC } from 'react';
import { makeStyles, Typography, Container } from '@material-ui/core';
import { GetInit } from 'src/type';
import { defaultStyles } from 'src/util';

type Props = {
  errorState: boolean;
  stylesData: GetInit['styles'] | undefined;
  titleData: GetInit['form'] | undefined;
  page: number;
};

const useStyles = makeStyles((theme) => ({
  header: (data) => {
    const { headerColor = defaultStyles.headerColor } = (data || {}) as GetInit['styles'];
    return {
      backgroundColor: headerColor,
      padding: '16px 0px',
      position: 'relative',
    };
  },
  title: (data) => {
    const { fontColor = defaultStyles.fontColor } = (data || {}) as GetInit['styles'];
    return {
      fontSize: '36px',
      fontWeight: 'bold',
      lineHeight: '1',
      color: fontColor,
      [theme.breakpoints.down('xs')]: {
        fontSize: '32px',
      },
    };
  },
  subtitle: (data) => {
    const { fontColor = defaultStyles.fontColor } = (data || {}) as GetInit['styles'];
    return {
      fontSize: '20px',
      color: fontColor,
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
      },
    };
  },
}));

export const Header: FC<Props> = ({ errorState, stylesData, titleData, page }) => {
  const styles = useStyles(stylesData);
  const titleDataByPage = page > 0 && titleData ? titleData[`step${page + 1}`] : titleData;
  const { title, subtitle } = titleDataByPage || {};
  return (
    <header className={styles.header}>
      <Container maxWidth="md">
        <Typography className={styles.title}>
          {errorState ? 'Error' : title || 'Untitled'}
        </Typography>
        <Typography className={styles.subtitle}>
          {errorState ? 'We are sorry, we couldn`t load the form' : subtitle || ''}
        </Typography>
      </Container>
    </header>
  );
};
