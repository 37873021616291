import { makeStyles } from '@material-ui/core';
import color from 'color';

import { defaultStyles } from 'src/util';
import { GetInit } from 'src/type';

export const useStyles = makeStyles((theme) => ({
  accordion: (apiStyles) => {
    const { tabsColor = defaultStyles.tabsColor } = (apiStyles || {}) as GetInit['styles'];
    const borderColor = color(tabsColor).fade(0.5).string();
    return {
      backgroundColor: 'transparent',
      margin: '0px -16px',
      borderTop: `solid 1px ${borderColor}`,
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        margin: '0px -16px',
      },
      '&:last-child': {
        borderBottom: `solid 1px ${borderColor}`,
      },
    };
  },
  summaryRoot: (apiStyles) => {
    const { tabsColor = defaultStyles.tabsColor } = (apiStyles || {}) as GetInit['styles'];
    return {
      color: tabsColor,
      transition: 'none',
      '&.Mui-expanded': {
        minHeight: '60px',
      },
    };
  },
  summaryTypographyExpanded: (apiStyles) => {
    const { tabsColor = defaultStyles.tabsColor } = (apiStyles || {}) as GetInit['styles'];
    return {
      color: tabsColor,
    };
  },
  summaryTypography: (apiStyles) => {
    const { tabsColor = defaultStyles.tabsColor } = (apiStyles || {}) as GetInit['styles'];
    return {
      color: tabsColor,
    };
  },
  summaryExpanded: (apiStyles) => {
    const { tabsColor = defaultStyles.tabsColor } = (apiStyles || {}) as GetInit['styles'];
    return {
      backgroundColor: 'transparent',
      minHeight: 'auto',
      color: tabsColor,
    };
  },
  summaryContent: {
    alignItems: 'center',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  detailsRoot: {
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  expandIcon: (apiStyles) => {
    const { tabsColor = defaultStyles.tabsColor } = (apiStyles || {}) as GetInit['styles'];
    return {
      backgroundColor: 'transparent !important',
      borderRadius: '0 !important',
      color: tabsColor,
    };
  },
}));
