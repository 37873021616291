import axios, { AxiosRequestConfig } from 'axios';
import { useDispatch } from 'react-redux';
import loGet from 'lodash/get';

import { ResponseWithErrors } from 'src/type';
import {
  RequestAction,
  ResponseAction,
  NetworkErrorPayload,
  APP__BEGIN_REQUEST,
  BeginRequestAction,
} from 'src/reducers';

const apiUrl = process.env.REACT_APP_API_URL;

const timeout = 10000;

type MethodImplement = <T extends ResponseWithErrors>(
  action: RequestAction,
) => Promise<[T | undefined, ResponseWithErrors | undefined]>;

const useAxiosMethod = (
  method: AxiosRequestConfig['method'],
  networkErrorPayload: NetworkErrorPayload,
): MethodImplement => {
  const dispatch = useDispatch();
  const methodImplement = <T extends ResponseWithErrors>({
    type,
    uri,
    payload,
    query,
    auth,
    previewResponse,
  }: RequestAction): Promise<[T | undefined, ResponseWithErrors | undefined]> => {
    const beginAction: BeginRequestAction = {
      type: APP__BEGIN_REQUEST,
    };
    dispatch(beginAction);
    if (previewResponse) {
      return new Promise((rs) => setTimeout(rs, 10)).then(() => {
        const response = previewResponse as T;
        const error = undefined;
        const action: ResponseAction<T> = {
          type,
          response,
          error,
          networkErrorPayload,
          requestPayload: payload,
        };
        dispatch(action);
        return [response, error];
      });
    }
    return axios({ method, url: `${apiUrl}${uri}`, data: payload, params: query, timeout, auth })
      .then(({ data: response }: { data: T }) => {
        if (response.status !== 200) {
          return {
            error: {
              status: response.status,
              statusMessage: response.statusMessage,
              errors: response.errors,
              errorMessage: response.errorMessage,
            },
          };
        }
        return { response };
      })
      .catch((error) => {
        const response: ResponseWithErrors = loGet(error, 'response.data');
        if (response)
          return {
            error: {
              status: response.status,
              statusMessage: response.statusMessage,
              errors: response.errors,
              errorMessage: response.errorMessage,
            },
          };

        return {
          error: {
            status: loGet(error, 'response.status') || loGet(error, 'request.status') || 500,
            statusMessage: error.message,
            errorMessage: error.message,
          },
        };
      })
      .then(({ response, error }: { response?: T; error?: ResponseWithErrors }) => {
        const action: ResponseAction<T> = {
          type,
          response,
          error,
          networkErrorPayload,
          requestPayload: payload,
        };
        dispatch(action);
        return [response, error];
      });
  };

  return methodImplement;
};

type ApiClient = {
  get: MethodImplement;
  post: MethodImplement;
};

export const useApiClient = (): ApiClient => {
  const get = useAxiosMethod('get', {
    title: 'Network Error.',
    message: 'We are sorry, we can not load the data.',
  });
  const post = useAxiosMethod('post', {
    title: 'Network Error.',
    message: 'We are sorry, we can not send your request.',
  });
  return {
    get,
    post,
  };
};
