import { FC, ReactNode, useState } from 'react';
import { Typography, Grid, TextField, FormHelperText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import { UseFormRegister } from 'react-hook-form';

import { useGeoSuggest, GoogleMapsJsonObject, AutocompletePrediction } from 'src/hooks';

type Props = {
  register: UseFormRegister<Record<string, unknown>>;
  error: boolean;
  loading: boolean;
  standard?: boolean;
  helperText?: ReactNode;
  defaultValue?: string;
  onSuggestSelect?: (nextValue: GoogleMapsJsonObject | null) => void;
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export const Address: FC<Props> = (props) => {
  const { defaultValue, error, helperText, loading, standard, onSuggestSelect, register } = props;
  const classes = useStyles();
  const [address, setAddress] = useState(defaultValue);
  const [options, suggestProps, googleMapsJsonObject, inputValue] = useGeoSuggest(
    defaultValue,
    onSuggestSelect,
  );
  const jsonObjectValue = googleMapsJsonObject ? JSON.stringify(googleMapsJsonObject) : '';
  const latitude = googleMapsJsonObject
    ? googleMapsJsonObject.geometry.location.lat().toString()
    : '0';
  const longitude = googleMapsJsonObject
    ? googleMapsJsonObject.geometry.location.lng().toString()
    : '0';
  const inputProps = loading ? { readOnly: true } : undefined;
  const value = standard ? address : inputValue;

  return (
    <>
      {standard ? (
        <TextField
          id="address"
          name="address_showcase"
          fullWidth
          label="Address *"
          error={error}
          variant="outlined"
          helperText={helperText}
          inputProps={inputProps}
          defaultValue={address}
          onChange={(event): void => setAddress(event.currentTarget.value)}
        />
      ) : (
        <>
          <Autocomplete
            id="address"
            fullWidth
            options={options}
            autoComplete
            clearOnBlur={false}
            noOptionsText="Empty list"
            disablePortal
            {...suggestProps}
            inputValue={inputValue}
            filterOptions={(opts): AutocompletePrediction[] => opts}
            renderInput={(params): JSX.Element => {
              return (
                <TextField
                  {...params}
                  name="address_showcase"
                  helperText={helperText}
                  error={error}
                  label="Address *"
                  variant="outlined"
                  fullWidth
                />
              );
            }}
            renderOption={(option): JSX.Element => {
              const matches = option.structured_formatting.main_text_matched_substrings || [];
              const parts = matches.length
                ? parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                  )
                : [];

              return (
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part) => (
                      <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                    &nbsp;
                    <Typography variant="body2" color="textSecondary" component="span">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
          />
          {!error && (
            <FormHelperText variant="filled">
              Do not include apartment numbers or any additional detail typically included in
              address line 2
            </FormHelperText>
          )}
        </>
      )}
      <input type="hidden" {...register('address', { value, shouldUnregister: true })} />
      <input
        type="hidden"
        {...register('googleMapsJsonObject', { value: jsonObjectValue, shouldUnregister: true })}
      />
      <input type="hidden" {...register('latitude', { value: latitude, shouldUnregister: true })} />
      <input
        type="hidden"
        {...register('longitude', { value: longitude, shouldUnregister: true })}
      />
    </>
  );
};
