import { FC, ChangeEvent } from 'react';
import { Tab, Tabs as TabsMUI, Box } from '@material-ui/core';

import { AppState } from 'src/reducers';

import { useStyles } from './Tabs.css';
import { TabPanel } from './TabPanel';
import { ActionContent } from '../Page2.type';

type Props = {
  appState: AppState;
  value: number;
  items: ActionContent[];
  handleChange: (event: ChangeEvent<Record<string, unknown>>, newValue: number) => void;
};

export const Tabs: FC<Props> = ({ appState, value, items, handleChange }) => {
  const apiStyles = appState.page1.response?.styles;
  const styles = useStyles(apiStyles);
  return (
    <Box pt={3}>
      <TabsMUI
        className={styles.tabs}
        classes={{ indicator: styles.indicator }}
        value={value || 0}
        onChange={handleChange}
        variant="fullWidth"
      >
        {items.map(({ id, icon, label }) => {
          return (
            <Tab
              id={id}
              classes={{ root: styles.root, wrapper: styles.wrapper }}
              label={
                <>
                  {icon}
                  <Box pl={1}>{label}</Box>
                </>
              }
              key={label}
            />
          );
        })}
      </TabsMUI>
      {items.map(({ element, label }, index) => {
        return (
          <TabPanel value={value} index={index} key={label}>
            {element}
          </TabPanel>
        );
      })}
    </Box>
  );
};
