import { ResponseWithErrors } from 'src/type';

import {
  NETWORK_ERROR__SET,
  NETWORK_ERROR__DISMIS,
  NetworkErrorState,
  NetworkErrorDismisAction,
  NetworkErrorSetAction,
} from './networkError.type';
import {
  BeginRequestAction,
  ResponseAction,
  APP__BEGIN_REQUEST,
  APP__GET_INIT,
  APP__POST_EMAIL_ACTION,
  APP__POST_STORE,
  APP__POST_PHONE_ACTION,
  APP__POST_TWEET_ACTION,
  APP__OAUTH_LOGIN_ACTION,
  OauthLoginAction,
  APP__GET_PREVIEW_INIT,
} from '../app/app.type';
import { TAB__SET, TabSetAction } from '../tab';

export const networkError = (
  state: NetworkErrorState = false,
  action:
    | BeginRequestAction
    | NetworkErrorDismisAction
    | TabSetAction
    | NetworkErrorSetAction
    | ResponseAction<ResponseWithErrors>
    | OauthLoginAction,
): NetworkErrorState => {
  switch (action.type) {
    case TAB__SET:
    case APP__BEGIN_REQUEST:
    case NETWORK_ERROR__DISMIS:
      return false;
    case APP__GET_INIT:
    case APP__GET_PREVIEW_INIT: {
      if (!action.error) return state;
      return action.networkErrorPayload;
    }
    case APP__POST_STORE:
    case APP__POST_EMAIL_ACTION:
    case APP__POST_PHONE_ACTION:
    case APP__POST_TWEET_ACTION: {
      const { error } = action;
      if (!error) return state;
      const validateErrors = error.errors || [];
      const [internalError] = validateErrors.filter(({ path }) =>
        ['_csrf', 'signature.uuid', 'signature_id'].includes(path),
      );
      if (!validateErrors.length || internalError) {
        return action.networkErrorPayload;
      }
      return state;
    }
    case NETWORK_ERROR__SET: {
      return action.payload;
    }
    case APP__OAUTH_LOGIN_ACTION:
      return action.payload?.twitterLoginStatus !== 'ok'
        ? {
            title: 'Authorization Error.',
            message: 'We are sorry, we can not send your request.',
          }
        : state;
    default:
      return state;
  }
};
