import { makeStyles } from '@material-ui/core';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import Snackbar from '@material-ui/core/Snackbar';
import { useNetworkStatus } from 'src/hooks/useNetworkStatus';

interface OfflineIndicationFC {
  (): JSX.Element;
}

const useStyles = makeStyles((theme) => {
  return {
    content: {
      background: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      minWidth: '11em',
      '& > *': {
        display: 'flex',
        alignItems: 'center',
      },
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  };
});

export const OfflineIndication: OfflineIndicationFC = () => {
  const css = useStyles();
  const isOnline = useNetworkStatus();

  return (
    <Snackbar
      ContentProps={{
        className: css.content,
      }}
      open={isOnline === false}
      message={
        <>
          <WifiOffIcon />
          No Internet
        </>
      }
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
};
