import { FC, useState, useEffect } from 'react';
import { Grid, makeStyles, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { useDispatch } from 'react-redux';
import omit from 'lodash/omit';

import { Address, Button } from 'src/blocks';
import { useApiClient } from 'src/hooks/useApiClient';
import { GoogleMapsJsonObject } from 'src/hooks';
import {
  APP__POST_STORE,
  FORM__SUBMIT_PAGE1,
  FormSubmitPage1Action,
  Page1SubmitPayload,
} from 'src/reducers';

import { makeSchema } from './Page1.schema';
import { Props, FormValues } from './Page1.type';

const basePathname = process.env.REACT_APP_API_BASE_PATHNAME;

const formatPhone = (required: boolean, phone?: string, country = 'US'): string => {
  if (!required && !phone) return '';
  if (!phone) throw new Error('Phone is undefined');
  const phoneUtil = PhoneNumberUtil.getInstance();
  const phoneNumber = phoneUtil.parseAndKeepRawInput(phone, country);
  return phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
};

const useStyles = makeStyles({
  root: {
    padding: '32px 0px',
  },
  rootInput: {
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  label: {
    display: 'none',
  },
  hidden: {
    display: 'none',
  },
});

export const Page1: FC<Props> = ({ appState, setPage, loading, onSubmitEvent }) => {
  const apiClient = useApiClient();
  const dispatch = useDispatch();
  const { urlQuery, page1, previewMode, page2 } = appState;
  const { uc, uf } = urlQuery || {};
  const [submitedPayload, setSubmitedPayload] = useState<Page1SubmitPayload | null>(null);
  const [checked, setChecked] = useState(true);
  const styles = useStyles();
  const [googleJson, setGoogleJson] = useState<GoogleMapsJsonObject | null>(null);

  const schema = makeSchema(appState);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    trigger,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const inputProps = loading ? { readOnly: true } : undefined;
  const showPhone = page1.response?.form.showPhone === true;
  const { addressType } = page1.response?.form || {};
  const addressStandard = addressType === 'STANDARD';

  const onSubmit = (values: FormValues): void => {
    if (appState.staticStepMode) {
      onSubmitEvent();
      return;
    }
    const phone = formatPhone(
      showPhone && !appState.previewMode,
      values.phone,
      page1.response?.campaignCountry,
    );

    setSubmitedPayload({
      addressType: addressType || 'GOOGLE',
      advocate: {
        ...omit(values, addressStandard ? ['latitude', 'longitude'] : ['city', 'zip']),
        googleMapsJsonObject: addressStandard
          ? undefined
          : (JSON.parse(values.googleMapsJsonObject || 'null') as google.maps.GeocoderResult),
        phone,
      },
      extra: {
        utmContent: urlQuery?.utm_content,
        utmMedium: urlQuery?.utm_medium,
        utmTerm: urlQuery?.utm_term,
        utmCampaign: urlQuery?.utm_campaign,
        utmSource: urlQuery?.utm_source,
        _uid: appState.uid || undefined,
      },
      // eslint-disable-next-line no-underscore-dangle
      _csrf: page1?.response?._csrf || '',
    });
  };

  useEffect(() => {
    if (!submitedPayload || !uc || !uf) return undefined;
    let actual = true;
    const formAction: FormSubmitPage1Action = {
      type: FORM__SUBMIT_PAGE1,
      payload: submitedPayload.advocate,
    };
    dispatch(formAction);
    apiClient
      .post({
        type: APP__POST_STORE,
        uri: `${basePathname}/${uc}/${uf}/store`,
        payload: submitedPayload,
        query: { token: urlQuery.token },
        previewResponse: previewMode ? page2.response : undefined,
      })
      .then(([, error]) => {
        if (!actual) return;
        setSubmitedPayload(null);
        if (!error) {
          setPage(1);
          return;
        }
        (error.errors || []).forEach(({ path, message }) => {
          const key = path.replace(/^advocate\./, '') as keyof FormValues;
          setError(key, { message });
        });
      });
    return (): void => {
      actual = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitedPayload, uc, uf]);

  useEffect(() => {
    if (googleJson?.place_id) trigger(['address', 'googleMapsJsonObject']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleJson?.place_id]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="store-form">
      <Grid container spacing={3} alignContent="center" justify="center">
        <Grid item xs={12} sm={6}>
          <TextField
            id="firstName"
            fullWidth
            error={!!errors.firstName}
            label="First name *"
            variant="outlined"
            {...register('firstName')}
            helperText={errors.firstName?.message}
            defaultValue={urlQuery.first_name}
            inputProps={inputProps}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="lastName"
            error={!!errors.lastName}
            fullWidth
            label="Last name *"
            variant="outlined"
            {...register('lastName')}
            helperText={errors.lastName?.message}
            defaultValue={urlQuery.last_name}
            inputProps={inputProps}
          />
        </Grid>
        <Grid item xs={12} sm={showPhone ? 6 : 12}>
          <TextField
            id="email"
            error={!!errors.email}
            fullWidth
            label="Email *"
            variant="outlined"
            {...register('email')}
            helperText={errors.email?.message}
            defaultValue={urlQuery.email}
            inputProps={inputProps}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={showPhone ? '' : styles.hidden}>
          <TextField
            id="phone"
            fullWidth
            label="Phone number *"
            error={!!errors.phone}
            variant="outlined"
            {...register('phone')}
            helperText={errors.phone?.message}
            defaultValue={urlQuery.phone}
            inputProps={inputProps}
            disabled={!showPhone}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={addressStandard ? 6 : 12}>
              <Address
                defaultValue={urlQuery.address}
                error={!!errors.googleMapsJsonObject || !!errors.address}
                helperText={errors.address?.message || errors.googleMapsJsonObject?.message}
                register={register}
                loading={loading}
                standard={addressStandard}
                onSuggestSelect={setGoogleJson}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={addressStandard ? '' : styles.hidden}>
              <TextField
                id="city"
                fullWidth
                label="City *"
                error={!!errors.city}
                variant="outlined"
                {...register('city')}
                helperText={errors.city?.message}
                inputProps={inputProps}
                defaultValue={urlQuery.city}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={addressStandard ? '' : styles.hidden}>
              <TextField
                id="zip"
                fullWidth
                label="Zip *"
                error={!!errors.zip}
                variant="outlined"
                {...register('zip')}
                helperText={errors.zip?.message}
                inputProps={inputProps}
                defaultValue={urlQuery.zip}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={page1.response?.form.hasOptInBox ? '' : styles.hidden}
          id="optInWraper"
        >
          <FormControlLabel
            control={
              <Checkbox
                id="optIn"
                checked={page1.response?.form.hasOptInBox ? checked : false}
                {...register('optIn')}
                onChange={(): void => setChecked(!checked)}
                inputProps={inputProps}
              />
            }
            label="I opt-in to receive updates about important future issues."
          />
        </Grid>
      </Grid>
      <Grid className={styles.root} container alignContent="center" justify="center">
        <Button type={loading ? 'button' : 'submit'} disabled={!!submitedPayload} id="submit-store">
          NEXT
        </Button>
      </Grid>
    </form>
  );
};
