import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => {
  return {
    section: {
      background: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      maxWidth: '30em',
      width: '100%',
      textAlign: 'center',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };
});

export const NotAvailable: FunctionComponent = () => {
  const css = useStyles();

  return (
    <section className={css.section}>
      <h1>Campaign Unavailable</h1>
      <p>The campaign is currently inaccessible.</p>
    </section>
  );
};
