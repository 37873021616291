export type Page1SubmitPayload = {
  addressType: 'GOOGLE' | 'STANDARD';
  advocate: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
    latitude?: number;
    longitude?: number;
    googleMapsJsonObject?: google.maps.GeocoderResult;
    city?: string;
    zip?: string;
    optIn: boolean;
  };
  extra: {
    utmContent?: string;
    utmMedium?: string;
    utmTerm?: string;
    utmCampaign?: string;
    utmSource?: string;
    _uid?: string;
  };
  _csrf: string;
};

export type Page2CommonPayload = {
  signature: {
    uuid: string;
  };
  _csrf: string;
  targeting: { uuid: string }[];
};

export type Page2EmailSubmitPayload = Page2CommonPayload & {
  email: {
    uuid: string;
    body: string;
    subject: string;
    byFaxAlso: boolean;
  };
};

export type Page2PhoneSubmitPayload = Page2CommonPayload & {
  phoneCall: {
    from: string;
    to?: string;
    script: string;
  };
};

export type Page2TweetSubmitPayload = Page2CommonPayload & {
  tweet: {
    body: string;
    username?: string;
    twitterSessionId?: string;
  };
};

export type FormState = {
  page1Form: null | Page1SubmitPayload['advocate'];
  page2EmailForm: null | Page2EmailSubmitPayload;
  page2PhoneForm: null | Page2PhoneSubmitPayload;
  page2TweetForm: null | Page2TweetSubmitPayload;
};

export const FORM__SUBMIT_PAGE1 = 'FORM__SUBMIT_PAGE1';
export const FORM__SUBMIT_PAGE2_EMAIL = 'FORM__SUBMIT_PAGE2_EMAIL';
export const FORM__SUBMIT_PAGE2_PHONE = 'FORM__SUBMIT_PAGE2_PHONE';
export const FORM__SUBMIT_PAGE2_TWEET = 'FORM__SUBMIT_PAGE2_TWEET';

export type FormSubmitPage1Action = {
  type: typeof FORM__SUBMIT_PAGE1;
  payload: Page1SubmitPayload['advocate'];
};

export type FormSubmitPage2EmailAction = {
  type: typeof FORM__SUBMIT_PAGE2_EMAIL;
  payload: Page2EmailSubmitPayload;
};

export type FormSubmitPage2PhoneAction = {
  type: typeof FORM__SUBMIT_PAGE2_PHONE;
  payload: Page2PhoneSubmitPayload;
};

export type FormSubmitPage2TweetAction = {
  type: typeof FORM__SUBMIT_PAGE2_TWEET;
  payload: Page2TweetSubmitPayload;
};
