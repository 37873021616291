import * as yup from 'yup';
import '../yup-phone';

import { GetInit } from 'src/type';
import { AppState } from 'src/reducers';

type SchemaType = yup.AnyObjectSchema;

const getZipValidation = (
  initResponse: GetInit | undefined,
  preview?: boolean,
): yup.StringSchema => {
  const baseShema = preview ? yup.string() : yup.string().required('Required field');
  if (initResponse?.campaignCountry === 'GB') {
    return preview
      ? baseShema.max(8, 'Must be at most 8 characters')
      : baseShema.min(5, 'Must be at least 5 characters').max(8, 'Must be at most 8 characters');
  }
  return preview ? baseShema : baseShema.matches(/^[0-9]{5}$/, 'Must be exactly 5 digits');
};

export const makeSchema = (appState: AppState): SchemaType => {
  const { previewMode, page1 } = appState;
  const initResponse = page1.response;
  const standard = initResponse?.form.addressType === 'STANDARD';

  const standartCityShema = previewMode
    ? yup.string().max(64, 'Must be at most 64 characters')
    : yup
        .string()
        .required('Required field')
        .min(3, 'Must be at least 3 characters')
        .max(64, 'Must be at most 64 characters');

  const shape = {
    firstName: previewMode
      ? yup.string().max(32, 'Must be at most 32 characters')
      : yup
          .string()
          .required('Required field')
          .min(3, 'Must be at least 3 characters')
          .max(32, 'Must be at most 32 characters'),
    lastName: previewMode
      ? yup.string().max(32, 'Must be at most 32 characters')
      : yup
          .string()
          .required('Required field')
          .min(3, 'Must be at least 3 characters')
          .max(32, 'Must be at most 32 characters'),
    email: previewMode
      ? yup.string().email().max(64, 'Must be at most 64 characters')
      : yup
          .string()
          .email()
          .required('Required field')
          .min(6, 'Must be at least 6 characters')
          .max(64, 'Must be at most 64 characters'),
    address: previewMode
      ? yup.string().max(255, 'Must be at most 255 characters')
      : yup
          .string()
          .required(
            standard
              ? 'Required field'
              : 'We were unable to find an address matching your input. Please try again and be less specific',
          )
          .min(8, 'Must be at least 8 characters')
          .max(255, 'Must be at most 255 characters'),

    latitude: yup.number(),
    longitude: yup.number(),
    googleMapsJsonObject:
      standard || previewMode ? yup.string() : yup.string().required('Required field'),

    city: standard ? standartCityShema : yup.string(),
    zip: standard ? getZipValidation(initResponse, previewMode) : yup.string(),

    optIn: yup.boolean().required(),
  };
  if (initResponse?.form.showPhone) {
    Object.assign(shape, {
      phone: previewMode
        ? yup.string().phone(initResponse?.campaignCountry)
        : yup.string().required('Required field').phone(initResponse?.campaignCountry),
    });
  }
  return yup.object().shape(shape) as unknown as SchemaType;
};
