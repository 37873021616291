import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  list: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 'inherit',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      border: 'solid 1px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
    },
  },
  box: {
    borderRadius: 4,
    padding: '0',
    display: 'block',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  subboxWrap: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    position: 'relative',
  },
  subbox: {
    overflow: 'auto',
    maxHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      border: 'solid 1px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
    },
  },
  error: {
    paddingLeft: 8,
    position: 'absolute',
    bottom: '-1.6em',
  },
  itemRoot: {
    height: '64px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    '&:last-child': {
      borderBottom: 0,
    },
  },
  inactive: {
    opacity: 0.3,
  },
  disabled: {
    opacity: '1 !important',
  },
  disabledBySend: {
    opacity: 0.4,
  },
  gutters: {
    padding: '8px 8px',
  },
  avatarRoot: {
    height: '48px',
    width: '48px',
  },
  primaryTextList: {
    color: '#666',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  disabledCheckCircle: {
    color: 'rgba(0, 0, 0, 0.12)',
  },
  checkCircle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  secondaryPhonesText: {
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    display: 'block',
  },
}));
