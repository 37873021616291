import { FC } from 'react';
import { Button } from 'src/blocks';

type Props = {
  active: boolean;
  handleClick: () => void;
  id?: string;
};

export const NextStepButton: FC<Props> = ({ active, handleClick, id }): JSX.Element | null => {
  if (!active) return null;
  return (
    <Button onClick={handleClick} id={id}>
      Next Step
    </Button>
  );
};
