import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState, APP__GET_INIT, APP__GET_PREVIEW_INIT } from 'src/reducers';
import { useApiClient } from './useApiClient';

const basePathname = process.env.REACT_APP_API_BASE_PATHNAME;

export const usePage1Request = (): RootState['app']['page1'] => {
  const apiClient = useApiClient();
  const appState = useSelector(({ app }: RootState) => app);
  const { uc, uf, token } = appState.urlQuery;
  const dispatch = useDispatch();

  const effect = (): void => {
    if (appState.page1.response || appState.page1.error) return;
    if (!uc || !uf) {
      dispatch({
        type: APP__GET_INIT,
        error: {
          status: 500,
          statusMessage: 'Required parameters are missing',
        },
        networkErrorPayload: {
          title: 'Configuration Error',
          message:
            'We are sorry, we can not show the form because the required parameters are missing.',
        },
      });
      return;
    }

    apiClient.get({
      type: appState.previewMode ? APP__GET_PREVIEW_INIT : APP__GET_INIT,
      uri: `${basePathname}/${uc}/${uf}/${appState.previewMode ? 'preview' : 'init'}`,
      query: { token },
    });
  };

  useEffect(effect, [uc, uf]); // eslint-disable-line react-hooks/exhaustive-deps

  return appState.page1;
};
