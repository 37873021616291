/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { UseAutocompleteProps } from '@material-ui/lab/useAutocomplete';

import { useDebounce } from './useDebounce';

export type PlaceType = {
  description: string;
  name: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: [
      {
        offset: number;
        length: number;
      },
    ];
  };
};

export type GoogleMapsJsonObject = google.maps.GeocoderResult | null;
export type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutocompleteService = google.maps.places.AutocompleteService;

type AutocompleteProps = Pick<
  UseAutocompleteProps<AutocompletePrediction, false, false, false>,
  'onChange' | 'onInputChange' | 'value' | 'getOptionLabel' | 'autoHighlight'
> & { loading: boolean };

const googleMapsScriptId = 'google-maps';

export const useGeoSuggest = (
  defaultCriteria?: string,
  onSelect?: (nextValue: GoogleMapsJsonObject | null) => void,
): [AutocompletePrediction[], AutocompleteProps, GoogleMapsJsonObject, string] => {
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [autocompleteService, setAutocompleteService] = useState<AutocompleteService | null>(null);
  const [inputValue, setInputValue] = useState(defaultCriteria || '');
  const debouncedInputValue = useDebounce(inputValue);
  const [options, setOptions] = useState<AutocompletePrediction[]>([]);
  const [value, setValue] = useState<AutocompletePrediction | null>(null);
  const [jsonObject, setJsonObject] = useState<GoogleMapsJsonObject>(null);

  useEffect(() => {
    if (mapsLoaded) return;
    if (!document.querySelector(`#${googleMapsScriptId}`)) {
      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.setAttribute('id', googleMapsScriptId);
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_KEY}&libraries=places&language=en`;
      const headElement = document.querySelector('head');
      if (headElement) headElement.appendChild(script);
      setMapsLoaded(true);
    }
  }, [mapsLoaded]);

  useEffect(() => {
    if (!mapsLoaded || !('google' in window) || autocompleteService) return;
    setAutocompleteService(new window.google.maps.places.AutocompleteService());
  }, [mapsLoaded, autocompleteService, debouncedInputValue]);

  useEffect(() => {
    let active = true;
    if (!autocompleteService) return undefined;

    if (debouncedInputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    autocompleteService.getPlacePredictions(
      { input: debouncedInputValue },
      (results?: AutocompletePrediction[]) => {
        if (!active) return;
        let newOptions: AutocompletePrediction[] = [];
        if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];
        setOptions(newOptions);
      },
    );

    return (): void => {
      active = false;
    };
  }, [value, debouncedInputValue, autocompleteService]);

  useEffect(() => {
    let actual = true;
    if (!value) {
      setJsonObject(null);
      return undefined;
    }
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: value.place_id }, (results, status): void => {
      const nextJsonObject = (actual && status === 'OK' && results[0]) || null;
      setJsonObject(nextJsonObject);
      if (onSelect) onSelect(nextJsonObject);
    });
    return (): void => {
      actual = false;
    };
  }, [value, onSelect]);

  useEffect(() => {
    if (!defaultCriteria || !autocompleteService) return;
    setInputValue(defaultCriteria);
  }, [defaultCriteria, autocompleteService]);

  return [
    options,
    {
      autoHighlight: true,
      getOptionLabel: (option): string =>
        `${option.structured_formatting.main_text} ${option.structured_formatting.secondary_text}`,
      onChange: (event, newValue): void => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      },
      onInputChange: (event, newInputValue): void => {
        setInputValue(newInputValue);
      },
      value,
      loading: false,
    },
    jsonObject,
    inputValue,
  ];
};
