import bagsnag from '@bugsnag/js';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { combineReducers, createStore } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import outdatedBrowserRework from 'outdated-browser-rework';
import 'outdated-browser-rework/dist/style.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { app, form, networkError, page, tab } from './reducers';
import { observeHeight } from './util/resizeObserver';

// https://caniuse.com/usage-table
outdatedBrowserRework({
  browserSupport: {
    Chrome: 32,
    Edge: 12,
    Safari: 10,
    'Mobile Safari': 10,
    Opera: 19,
    Firefox: 27,
    IE: false,
  },
  isUnknownBrowserOK: true,
});

const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY!;
bagsnag.start({ apiKey: bugsnagApiKey });

const reducers = combineReducers({ app, form, networkError, page, tab });
const store = createStore(reducers, {});
const rootElement = document.getElementById('root');

ReactDOM.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </StrictMode>,
  rootElement,
);

observeHeight(rootElement!);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
